import { useTheme } from 'styled-components'

export type HirePortTheme = {
  themeName: string;
  primaryColor: string;
  hoverColor: string;
  ghostHoverColor: string;
  activeColor: string;
  lozengeColor: string;
  backgroundColor: string;
  disabledSwitchColor: string;
  radius: string;
  radiusSmall: string;
  simplified: boolean;
  renderPageHeader: boolean;
};

export const defaultHireportTheme: HirePortTheme = {
  themeName: 'default',
  primaryColor: '#34ce72',
  hoverColor: '#22bf61',
  ghostHoverColor: 'rgb(34, 191, 97, 0.1)',
  activeColor: '#29ae5e',
  lozengeColor: '#f2f6fa',
  backgroundColor: 'transparent',
  disabledSwitchColor: '#627d95',
  radius: '.5rem',
  radiusSmall: '.25rem',
  simplified: false,
  renderPageHeader: true,
};

const useHirePortTheme = () => {
  return useTheme() as HirePortTheme;
}

export default useHirePortTheme;
