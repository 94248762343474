import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '../../components/layout/Header';
import PageWrapper from '../../components/layout/PageWrapper';

import styled from 'styled-components';
import { useAppSelector } from '../../store/hooks';
import { Form, message } from 'antd';
import { ClientFeeTemplate } from '../../types';
import {
  HIREPORT_DEFAULT_COMPENSATION_TYPE,
  HIREPORT_DEFAULT_GUARANTEE_PERIOD,
} from '../../constants/recruiters';
import {
  createCompanyFeeTemplate,
  getCompanyFeeTemplates,
} from '../../api/company';
import Button from '../../UI/buttons/Button';
import { JobActionButton } from '../../components/settings/atsSettings/styles';
import { MASTER_CONTRACTS } from '../../constants/routes';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';
import MasterContractForm from '../../components/client/MasterContractForm';
import { TEST_UI_KEYS, withTestUIWrapper } from '../../components/helper/TestUIWrapper';

const FeeTemplateTableRow = styled.div`
  display: flex;
  flexdirection: row;
  alignitems: center;
  justifycontent: space-between;
  borderbottom: 1px solid #dae5ec;
  padding: 10px 0px;
  padding-left: 0.5rem;
  border-radius: ${({ theme }) => theme.radius};
`;

const FeeTemplateTable = styled.div`
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const FormWrapper = styled.div`
  padding: 2rem;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.radius};
  @media (max-width: 576px) {
    padding: 0 1rem;
  }
`;

const MasterContractsPageNative = () => {
  const { t } = useTranslation();
  const history = useHistoryWithUrlBase();
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [companyFeeTemplates, setCompanyFeeTemplates] = useState<
    ClientFeeTemplate[]
  >([]);
  const [showSetupForm, setShowSetupForm] = useState(false);

  const initialFormValues = {
    recruiter_compensation_type: HIREPORT_DEFAULT_COMPENSATION_TYPE,
    guarantee_period: HIREPORT_DEFAULT_GUARANTEE_PERIOD,
  };

  useEffect(() => {
    if (!user?.company?.company_wide_fee_settings_enabled) {
      history.push('/profile');
    }
    getCompanyFeeTemplates()
      .then((res) => {
        setCompanyFeeTemplates(res.data);
      })
      .catch(() => { });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data: FormData) => {
    setIsLoading(true);
    createCompanyFeeTemplate(data)
      .then((res) => {
        setCompanyFeeTemplates([res.data, ...companyFeeTemplates]);
        setIsLoading(false);
        setShowSetupForm(!showSetupForm);
        form.setFieldsValue(initialFormValues);
        message.success(t('FEE_SETTINGS_CREATED'), 5);
      }).catch(() => setIsLoading(false));
  };

  return (
    <>
      <Header title={t('MENU_MASTER_CONTRACTS_TOOLTIP')}>
        <span></span>
      </Header>
      <FormWrapper>
        {!user?.permissions || Object.keys(user.permissions).length === 0 || user.permissions.create_mastercontracts ? (
          <Button type="primary" onClick={() => setShowSetupForm(!showSetupForm)}>
            {t('CREATE_FEE_SETUP')}
          </Button>
        ) : null}
        {showSetupForm && (
          <MasterContractForm
            isLoading={isLoading}
            submitLabel="CREATE"
            onSubmit={onSubmit}
            onCancel={() => setShowSetupForm(!showSetupForm)}
          />
        )}
        {!showSetupForm && (
          <FeeTemplateTable>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: '1px solid #DAE5EC',
                padding: '6px 0',
                paddingLeft: '0.5rem',
                marginTop: '4rem',
                color: '#627D95',
                fontWeight: 600,
              }}
            >
              <div style={{ paddingRight: '2rem' }}>
                {t('MASTER_CONTRACT_NAME')}
              </div>
            </div>

            <div
              style={{
                overflow: 'auto',
              }}
            >
              {companyFeeTemplates.map((feeTemplate) => (
                <FeeTemplateTableRow
                  style={{
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #DAE5EC',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '1.5rem' }}>
                      {feeTemplate.name}
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      paddingRight: '0.5rem',
                    }}
                  >
                    {!user?.permissions || Object.keys(user.permissions).length === 0 || user.permissions.edit_mastercontracts ? (
                        <JobActionButton
                          size="small"
                          type="primary"
                          onClick={() =>
                            history.push(`${MASTER_CONTRACTS}/${feeTemplate.id}`)
                          }
                        >
                          <div
                            style={{ paddingRight: '1rem', paddingLeft: '1rem' }}
                          >
                            {t('CONFIGURE')}
                          </div>
                        </JobActionButton>
                    ) : null}
                  </div>
                </FeeTemplateTableRow>
              ))}
            </div>
          </FeeTemplateTable>
        )}
      </FormWrapper>
    </>
  );
};

const MasterContractsFrame = withTestUIWrapper(MasterContractsPageNative, () => ({ testKey: TEST_UI_KEYS.MasterContracts }));

const MasterContractsPage = () => (
  <PageWrapper>
    <MasterContractsFrame />
  </PageWrapper>
)


export default MasterContractsPage;
