
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  CandidateInfo,
  Job,
  Skill,
  Certificate,
  Education,
} from '../../../types';
import { introduceCandidate } from '../../../api/candidates';
import { JOBS } from '../../../constants/routes';
import { mapEmploymentTypeFormToApi } from '../../form/shared/employment-types/helpers';

interface Iprops {
  candidate: CandidateInfo,
  setCandidate: React.Dispatch<React.SetStateAction<CandidateInfo | null>>,
  job: Job | null,
  jobId: string,
  addToList: React.Dispatch<React.SetStateAction<string[]>>,
  skills: Skill[],
  certificates: Certificate[],
  education: Education[],
  onConfirmFinished?: any,
}

const useCandidateIntroduceModal = ({
  candidate,
  setCandidate,
  job,
  jobId,
  addToList,
  skills,
  certificates,
  education,
  onConfirmFinished
}: Iprops) => {
  const [t] = useTranslation();

  const [visible, setVisible] = useState(!!candidate);
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [form] = Form.useForm();
  const [confirmClose, setConfirmClose] = useState(false);

  useEffect(() => {
    setVisible(!!candidate);
  }, [candidate]);

  useEffect(() => {
    if (!visible) {
      setCandidate(null);
      setAgree(false);
      setError('');
    }
  }, [visible, setCandidate]);

  useEffect(() => {
    const isAlreadyIntroduced =
      candidate.interviews?.map((int) => int.job.id === job?.id) || [];
    if (isAlreadyIntroduced.includes(true)) {
      setError(t('ALREADY_APPLIED'));
    }
  }, [t, job, candidate]);

  const onSubmit = (values: any) => {
    let data: any = {
      comments: values.comments ? values.comments : null,
      candidate_profile: {
        skills: values.skills,
        certificates: values.certificates,
        salary: values.salary,
        bonus: values.bonus,
        budget_lease_car: values.budget_lease_car,
        salary_13th_month: values.salary_13th_month,
        extra_salary: values.extra_salary,
        budget_education: values.budget_education,
        pension_scheme: values.pension_scheme,
        km_reimbursement: values.km_reimbursement,
        need_phone: values.need_phone,
        need_laptop: values.need_laptop,
        compensation: mapEmploymentTypeFormToApi(values.compensation),
      },
      job: jobId,
    };

    introduceCandidate(candidate.id, data)
      .then((res: any) => {
        message.success(t('CANDIDATE_INTRODUCE_MESSAGE'));
        setLoading(false);
        history.push(JOBS + '/' + jobId);
        addToList((prevState) => [...prevState, candidate.id]);
        setCandidate(null);
        onConfirmFinished();
      })
      .catch((e) => {
        setLoading(false);
        e.response?.data.message && setError(e.response?.data.message);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      skills: candidate.skills.map(
        (sk) => skills.find((s) => s.name === sk)?.id,
      ),
      certificates: candidate.certificates.map(
        (ct) => certificates.find((s) => s.name === ct)?.id,
      ),
      education: candidate.education.map(
        (ed) => education.find((e) => e.name === ed)?.id,
      ),
      salary: candidate.salary,
      bonus: candidate.bonus,
      budget_lease_car: candidate.budget_lease_car,
      salary_13th_month: candidate.salary_13th_month,
      budget_mobile: candidate.budget_mobile,
      extra_salary: candidate.extra_salary,
      budget_education: candidate.budget_education,
      pension_scheme: candidate.pension_scheme,
      km_reimbursement: candidate.km_reimbursement,
      need_phone: candidate.need_phone,
      need_laptop: candidate.need_laptop,
    });
  }, [candidate, form, skills, certificates, education]);

  const showEditCandidate = (id: string) => {
    params.set('edit', id);
    history.push({ search: params.toString() });
  };

  const onFieldsChange = useCallback(() => {
    if (confirmClose) return;
    setConfirmClose(true);
  }, [confirmClose]);


  return {
    actions: {
      setVisible,
      showEditCandidate,
      onSubmit,
      onFieldsChange,
      setAgree,
      setShowConfirmModal
    },
    data: {
      visible,
      confirmClose,
      form,
      agree,
      loading,
      error,
      showConfirmModal,
    },
  };
};

export default useCandidateIntroduceModal;
