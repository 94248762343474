import React, { useEffect, useState } from 'react';
import FormPopUp from '../form/FormPopUp';
import { useTranslation } from 'react-i18next';
import { Col, Form, message, Row } from 'antd';
import { CandidateInfo, InterviewRecruiter } from '../../types';
import { CANDIDATES } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { getCandidate, rejectCandidateInterview } from '../../api/candidates';
import CandidateCompany from './CandidateCompany';
import CandidatePersonalInfo from './CandidatePersonalInfo';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import FormItem from '../form/FormItem';
import styled from 'styled-components';
import {
  confirmOffer,
  disagreeOffer,
  getRejectedFeedback,
  rejectOffer,
  sendOffer,
} from '../../api/interviews';
import {
  COMPLETED,
  REJECTED,
  WAITING_CLIENT_OFFER,
  WAITING_RECRUITER_OFFER,
} from '../../constants/statuses';
import { ErrorMessage } from '../auth/LoginForm';
import Loader from '../../UI/Loader';
import Button from '../../UI/buttons/Button';
import OfferResult from './kanban/OfferResult';
import Textarea from '../../UI/inputs/Textarea';
import OfferText from './kanban/OfferText';
import CandidateOfferForm from '../form/CandidateOfferForm';
import moment from 'moment';
import {
  SHOW_OFFER_PARAM,
  INTERVIEW_ID_PARAM,
  STAGE_PARAM,
  SHOW_FEEDBACK_PARAM,
} from '../../constants/queryParams';
import { mapEmploymentTypeFormToHtmlForm } from '../form/shared/employment-types/helpers';
import useEmploymentTypes from '../../hooks/useEmploymentTypes';
import useCurrency from '../../hooks/useCurrency';

interface OfferProps {
  collect: (info: CandidateInfo[]) => CandidateInfo[];
  onSuccess: (id: string, stageName: string, candidateID: string) => void;
  removeItem: (
    candidateID: string,
    stageName: string,
    interviewId: string,
  ) => void;
  setShowInterviewStageModal: (show: boolean) => void;
}

const ModalContent = styled.div`
  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  & + button {
    margin-left: 1rem;
  }
  & span {
    display: block;
    text-align: center;
    width: 100%;
  }
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
`;
const StyledTextarea = styled(FormItem)`
  margin: 2.5rem 0;
`;

const CandidateOfferModal = ({
  collect,
  onSuccess,
  removeItem,
  setShowInterviewStageModal,
}: OfferProps) => {
  const [rejection, setRejection] = useState<string>('');
  const [loadSave, setLoadSave] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [file, setFile] = useState('');
  const [candidate, setCandidate] = useState<CandidateInfo | null>(null);
  const { user } = useAppSelector((state) => state.user);
  const isClient = user?.role === CLIENT_ROLE;
  const isRecruiter = user?.role === RECRUITER_ROLE;

  const history = useHistory();
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const search = new URLSearchParams(history.location.search);

  const offer = search.get(SHOW_OFFER_PARAM);
  const interview_id = search.get(INTERVIEW_ID_PARAM);

  const interview = candidate
    ? candidate.interviews.find((el) => el.id === interview_id)
    : null;
  const currency = useCurrency(interview?.job.offer.compensation_currency);
  const waitingForRecruiter =
    interview?.current_offer.status === WAITING_RECRUITER_OFFER;
  const waitingForClient =
    interview?.current_offer.status === WAITING_CLIENT_OFFER;
  const waitingDecision = interview?.current_offer.status === COMPLETED;
  const isResend = !!(waitingForClient && interview?.current_offer.offer_doc);

  useEffect(() => {
    if (offer) {
      getCandidate(offer).then((res) => {
        const info = collect([res.data])[0];
        const int = info.interviews.find((el) => el.id === interview_id);
        if (
          int &&
          (int?.current_offer?.status === COMPLETED || int?.status === REJECTED)
        ) {
          return history.push(CANDIDATES + '?filter=in-process');
        }
        setVisible(true);
        setLoading(true);
        setCandidate(info);
        setLoading(false);
        form.setFieldsValue({
          ...form.getFieldsValue(),
          compensation: { type: int?.job.offer.employment_type.type },
          compensation_currency: int?.job.offer.compensation_currency,
        })
      });
    } else {
      setCandidate(null);
    }
  }, [form, offer, collect, history, interview_id]);

  useEffect(() => {
    if (isResend) {
      const info = { ...interview?.current_offer };
      if (info && info?.offer_doc) {
        // @ts-ignore
        delete info?.offer_doc;
      }
      form.setFieldsValue({
        ...info,
        start_date: moment(info.start_date),
      });
    }
  }, [isResend, form, interview, history]);

  const close = () => {
    search.delete(SHOW_OFFER_PARAM);
    const query = search.toString();
    history.push(CANDIDATES + '?' + query);

    setRejection('');
    setCandidate(null);
    setVisible(false);
    setFile('');
    form.resetFields();
  };

  const closeAllModals = () => {
    setShowInterviewStageModal(false);

    const params = new URLSearchParams(history.location.search);
    search.delete(SHOW_OFFER_PARAM);
    params.delete(STAGE_PARAM);
    params.delete(SHOW_FEEDBACK_PARAM);

    const query = params.toString();

    setRejection('');
    setCandidate(null);
    setVisible(false);
    setFile('');
    form.resetFields();

    history.push(CANDIDATES + '?' + query);
  };

  const onFinish = (values: any) => {
    setLoadSave(true)

    const offer_doc = new FormData();
    if (file && file.startsWith('http')) {
    } else {
      offer_doc.append('offer_doc', values.offer_doc.file.originFileObj);
    }
    delete values.offer_doc;
    offer_doc.append('start_date', values.start_date.format('YYYY-MM-DD'));
    delete values.start_date;
    if (!values.comment) {
      values.comment = '';
    }
    // because multipart/form data can't handle nested values, need to massage it to the right format:
    const htmlFormValues = mapEmploymentTypeFormToHtmlForm({
      ...values.compensation,
      type: { id: employmentType?.id },
    });
    Object.entries(htmlFormValues).forEach(([key, value]) => {
      values[`compensation.${key}`] = value;
    });
    Object.keys(values).forEach((value) => {
      offer_doc.append(value, values[value]);
    });
    interview_id &&
      sendOffer(interview_id, offer_doc)
        .then((_res) => {
          candidate &&
            interview?.current_stage.stage_name &&
            onSuccess(
              interview_id,
              interview?.current_stage.stage_name.toLowerCase(),
              candidate?.id,
            );
          close();
          message.success(t('OFFER_SENT'), 3);
          setLoadSave(false);
        })
        .catch((e) => {
          message.error(e.response?.data?.message, 3);
          setLoadSave(false);
        });
  };

  const handleAccept = () => {
    interview_id &&
      confirmOffer(interview_id)
        .then(() => {
          candidate &&
            interview?.current_stage.stage_name &&
            onSuccess(
              interview_id,
              interview?.current_stage.stage_name.toLowerCase(),
              candidate?.id,
            );
          close();
          message.success(t('OFFER_CONFIRMED'), 3);
        })
        .catch((e) => message.error(e.response?.data?.message, 3));
  };

  const handleReject = (text = comment) => {
    interview_id &&
      (isRecruiter
        ? rejectOffer(interview_id, { rejection_reason: text })
          .then(() => {
            candidate &&
              interview &&
              removeItem(
                candidate.id,
                interview.current_stage?.stage_name.toLowerCase(),
                interview_id,
              );
            closeAllModals();

            message.success(t('OFFER_REJECTED'), 3);
          })
          .catch((e) => message.error(e.response?.data?.message, 3))

        : rejectCandidateInterview(interview_id, { rejection_reason: text })
          .then(() => {
            candidate &&
              interview &&
              removeItem(candidate.id, 'offered', interview_id);

            closeAllModals()

            message.success(t('OFFER_REJECTED'), 3);
          })
          .catch((e) => message.error(e.response?.data?.message, 3)));
  };

  const handleDisagree = (text = comment) => {
    interview_id &&
      disagreeOffer(interview_id, { rejection_reason: text })
        .then((res) => {
          candidate &&
            interview &&
            onSuccess(
              interview_id,
              interview?.current_stage.stage_name.toLowerCase(),
              candidate?.id,
            );
          close();
          message.success(t('OFFER_REJECTED'), 3);
        })
        .catch((e) => message.error(e.response?.data?.message, 3));
  };

  const employmentTypes = useEmploymentTypes();

  useEffect(() => {
    if (isResend) {
      interview_id &&
        getRejectedFeedback(interview_id).then((res) => {
          setRejection(res.data.rejection_reason);
        });
    }
  }, [isResend, interview_id]);

  const employmentType = employmentTypes.find((employmentType) => employmentType.id === interview?.job.offer.employment_type.type.id);

  return (
    <FormPopUp
      visible={visible}
      setVisible={setVisible}
      title={t('OFFER')}
      onClose={() => {
        form.resetFields();
        close();
      }}
    >
      <Loader spinning={loading}>
        {candidate && (
          <>
            {isClient && (
              <>
                <CandidateCompany
                  role={interview?.job?.title}
                  company={`${t('AT')} ${interview?.job.company.name}`}
                  companyId={interview?.job.company.id}
                  image={interview?.job.company.image}
                />
                <CandidatePersonalInfo
                  name={candidate?.name || ''}
                  recruiter={interview?.recruiter as InterviewRecruiter}
                  color={candidate?.color}
                  role={candidate?.current_role}
                  company={`${t('AT')} ${candidate?.current_company}`}
                />
              </>
            )}
            {isRecruiter && (
              <>
                <CandidateCompany
                  role={interview?.job?.title}
                  company={`${t('AT')} ${interview?.job.company.name}`}
                  companyId={interview?.job.company.id}
                  image={interview?.job.company.image}
                />
                <CandidatePersonalInfo
                  name={candidate?.name || ''}
                  color={candidate?.color}
                  role={candidate?.current_role}
                  offer={
                    interview?.current_offer.offer_doc
                      ? interview?.current_offer.offer_doc
                      : undefined
                  }
                  company={`${t('AT')} ${candidate?.current_company}`}
                />
              </>
            )}
            <ModalContent>
              {isClient ? (
                waitingForRecruiter ? (
                  <>
                    <ErrorMessage
                      message={t('WAITING_RECRUITER')}
                      type="success"
                    />
                    {interview?.current_offer && <OfferResult offer={interview.current_offer} />}
                  </>
                ) : (
                  <>
                    <Row gutter={30}>
                      <Col md={8}>
                        <OfferText
                          title={t('STATUS')}
                          text={t(
                            isResend
                              ? 'REJECTED_BY_RECRUITER'
                              : waitingForClient
                                ? 'NEED_ACTION'
                                : 'WAITING_RECRUITER',
                          )}
                          direction={'vertical'}
                        />
                      </Col>
                      <Col md={16}>
                        <OfferText
                          title={
                            isResend
                              ? 'Recruiter ' + t('COMMENT').toLowerCase()
                              : t('COMMENT')
                          }
                          text={rejection}
                          direction={'vertical'}
                        />
                      </Col>
                    </Row>
                    {employmentType &&
                      <CandidateOfferForm
                        form={form}
                        file={file}
                        onFinish={onFinish}
                        onReject={handleReject}
                        setFile={setFile}
                        isResend={isResend}
                        loading={loadSave}
                        defaultCurrency={currency}
                      />
                    }
                  </>
                )
              ) : (
                ''
              )}
              {isRecruiter ? (
                waitingForClient ? (
                  <>
                    <ErrorMessage
                      message={t('WAITING_CLIENT_OFFER')}
                      type="success"
                    />
                  </>
                ) : waitingDecision ? (
                  <>
                    <ErrorMessage
                      message={t('WAITING_DECISION')}
                      type="success"
                    />
                  </>
                ) : (
                  <>
                    {interview?.current_offer && (
                      <OfferResult offer={interview?.current_offer} />
                    )}
                    <Form
                      layout="vertical"
                      name="add-client"
                      onFinish={onFinish}
                      scrollToFirstError={true}
                    >
                      <StyledTextarea label={t('COMMENT')}>
                        <Textarea
                          autoSize={{ minRows: 5 }}
                          onChange={(e: any) => setComment(e.target.value)}
                        />
                        {error && (
                          <div className="ant-form-item-explain ant-form-item-explain-error">
                            <div role="alert">{t('REQUIRED_FIELD')}</div>
                          </div>
                        )}
                      </StyledTextarea>
                    </Form>
                    <ActionWrapper>
                      <StyledButton
                        size="large"
                        type="primary"
                        onClick={handleAccept}
                      >
                        {t('ACCEPT_OFFER')}
                      </StyledButton>
                      <StyledButton
                        size="large"
                        type="default"
                        onClick={() => handleDisagree()}
                      >
                        {t('REQUEST_OFFER')}
                      </StyledButton>
                      <StyledButton
                        danger
                        size="large"
                        type="default"
                        onClick={() => {
                          if (comment) {
                            handleReject();
                          } else {
                            setError(true);
                          }
                        }}
                      >
                        {t('REJECT_OFFER')}
                      </StyledButton>
                    </ActionWrapper>
                  </>
                )
              ) : (
                ''
              )}
            </ModalContent>
          </>
        )}
      </Loader>
    </FormPopUp>
  );
};

export default CandidateOfferModal;
