import { TEST_UI_KEYS, withTestUIWrapper } from '../components/helper/TestUIWrapper';
import PageWrapper from '../components/layout/PageWrapper';

export const InvoicingFrameNative = () => <>
  No native UI for Invoicing!
</>

const InvoicingFrame = withTestUIWrapper(InvoicingFrameNative, () => ({ testKey: TEST_UI_KEYS.Invoicing }));

const InvoicingPage = () => (
  <PageWrapper>
    <InvoicingFrame />
  </PageWrapper>
)

export default InvoicingPage;
