import { useCallback, useEffect, useState } from "react";
import { showKomboConnect } from "@kombo-api/connect"
import { useHistoryWithUrlBase } from "../../hooks/useHirePortContext";
import { postMessage } from "../../components/helper/browserPlugins";

const EmbedActivate = () => {
  const history = useHistoryWithUrlBase();
  const { location } = history;
  const link_token = new URLSearchParams(location.search).get("link_token");
  const [isConnectComplete, setIsConnectComplete] = useState(false);
  const [connectionError, setConnectionError] = useState("");

  const connect = useCallback(async () => {
    if (!link_token)
      return;
    try {
      const decoded = atob(link_token);
      await showKomboConnect(decoded);
      setIsConnectComplete(true);
      postMessage({ event: "UIActionComplete", action: "activate" });
    } catch (e: unknown) {
      if (e instanceof DOMException && e.name === "InvalidCharacterError") {
        setConnectionError("Invalid Token Format");
        return;
      }
      throw e;
    }
  }, [link_token])

  useEffect(() => { connect() }, [connect])

  return (
    <h1>
      {isConnectComplete && "Activation complete"}
      {!isConnectComplete && !connectionError && "Activating..."}
      {!isConnectComplete && connectionError && `Error: ${connectionError}`}
    </h1>
  );
};

export default EmbedActivate;
