import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Contact } from '../../../types';
import { Room } from '../../../components/messages/Room';
import { useLocation } from 'react-router-dom';
import { getAtsCandidates, findAtsJobsByRemoteId } from '../../../api/integrations';
import { getInterview } from '../../../api/interviews';
import { getToken } from '../../../utils/localStorage';
import useHirePortContext from '../../../hooks/useHirePortContext';
import { postMessage } from '../../../components/helper/browserPlugins';

const PageLayout = styled.div`
  min-height: 100vh;
`;

type LoadingState = 'Loading' | 'NotConnected' | 'NotFound' | 'Loaded';

const ChromiumChatPage = () => {
  const location = useLocation();
  const { urlBase } = useHirePortContext();
  const [contact, setContact] = useState<Contact | null>(null);
  const [interviewId, setInterviewId] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>('Loading');

  const loadContact = useCallback(async () => {
    const query = Object.fromEntries(new URLSearchParams(location.search));
    const { remote_job_id, remote_candidate_id, remote_interview_id } = query;
    if (!remote_job_id || (!remote_candidate_id && !remote_interview_id))
      return;
    const atsJob = (await findAtsJobsByRemoteId(remote_job_id.toString())).find(() => true);
    if (!atsJob || !atsJob.local_job) {
      setLoadingState('NotConnected');
      return;
    }
    const atsCandidates = await getAtsCandidates(atsJob.local_job.id) || [];
    const mappedCandidate = atsCandidates.find((mapping) =>
      mapping.ats_candidate.ats_id === remote_candidate_id?.toString()
      || mapping.interview.ats_id === remote_interview_id?.toString()
    );
    if (!mappedCandidate) {
      setLoadingState('NotFound');
      return;
    }
    const interviewId = mappedCandidate.interview.id;
    const interview = await getInterview(interviewId);
    if (!interview) {
      setLoadingState('NotFound');
      return;
    }
    setContact(interview.data.recruiter);
    setInterviewId(interviewId);
    setLoadingState('Loaded');
  }, [location]);

  useEffect(() => {
    loadContact();
  }, [loadContact]);

  useEffect(() => {
    if (loadingState === 'NotFound')
      postMessage({ event: 'UIInterfaceError', interface: 'chat', error: 'not_found' });
  }, [loadingState]);

  if (['NotFound'].includes(loadingState))
    return <h1>There was a problem with your job connection. Please contact support.</h1>;

  if (['NotConnected'].includes(loadingState))
    return <h1>You have not connected your account to HirePort yet. Click <a href={`${urlBase}/redirect?to=/ats-settings&token=${getToken('access_token')}`} target='_blank' rel='noreferrer'>here</a> to set up a connection.</h1>

  if (!contact || !interviewId || ['Loading'].includes(loadingState))
    return <h1>Loading...</h1>;

  return (
    <PageLayout>
      <Room
        showContactPanel={false}
        showHeader={false}
        searchQuery={''}
        contact={contact}
        className={contact ? '' : 'hide-mobile'}
        setContact={() => { }}
        interviewId={interviewId}
      />
    </PageLayout>
  );
};

export default ChromiumChatPage;
