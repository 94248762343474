import { Switch as AntSwitch } from 'antd';
import styled from 'styled-components';

const Switch = styled(AntSwitch)`
  &.ant-switch {
    background-color: ${({ theme }) => theme.disabledSwitchColor};
    margin-right: 8px;
  }
  &.ant-switch.ant-switch-checked {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export default Switch;
