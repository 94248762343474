import React from 'react';
import SingleJob from '../SingleJob';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';
import SmallBackButton from '../../UI/buttons/SmallBackButton';
import { useParams } from 'react-router-dom';

const EmbedSingleJobPage = () => {
  const history = useHistoryWithUrlBase();
  const { id } = useParams<{ id: string, back_url?: string }>();
  const backUrl = new URLSearchParams(history.location.search).get("back_url")
  return (
    <>
      <SmallBackButton size="middle" onClick={() => { history.push(backUrl || `/assign-recruiters?local_id=${id}`) }} />
      <SingleJob
        isPublic={false}
        showBackButton={false}
      />
    </>
  )
};

export default EmbedSingleJobPage;
