import { Form } from 'antd';
import type {FormItemProps} from 'antd'
import React from 'react';
import styled from 'styled-components';

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 1.5rem;

  &.no-margin {
    margin-bottom: 0;
  }

  &.ant-form-item-has-error {
    margin-bottom: 0;
  }

  &.ant-form-item-with-help {
    margin-bottom: 0;
  }

  & .ant-form-item-required:before {
    color: #627d95 !important;
    font-size: 0.4rem !important;
    content: "required" !important;
    margin-right: 0 !important;
    position: absolute;
    right: -0.5rem;
    transform: translateX(100%);
    border: 1px solid #aebeca;
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-weight: normal;
  }

  & .ant-form-item-label {
    & label {
      font-size: 0.625rem;
      font-weight: bold;
      line-height: 1.6;
      letter-spacing: 0.8px;
      color: #061c2e;
      text-transform: uppercase;
      &:after {
        display: none;
      }
    }
  }
  &.text-right {
    text-align: right;
  }
  &.inline-form {
    & .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
    & .ant-form-item {
      width: 100%;
    }
  }
  &.inline-checkbox {
    align-items: center;
    display: flex;
    flex-direction: row;
    & .ant-form-item-control-input{
    min-height: auto;
    }
    & .ant-form-item-label {
    padding: 0;
    min-width: 30%;
    }
    & .ant-form-item-control {
      margin-left: 1rem;
    }
  }
  &.width-gender {
    position: relative;

    & .female-count {
      position: absolute;
      top: 52%;
      left: 53%;
      transform: translateY(-50%);
      font-size: 0.875rem;
      line-height: 1.43;
      color: #061c2e;
    }

    & .female-count-alt {
      left: 36%;
    }
  }

  &.gender {
    & .ant-form-item-control-input-content {
      & input {
        width: 50%;
      }

      & span {
        font-size: 0.875rem;
        color: #061c2e;
        margin-left: 0.5rem;
      }
    }
  }

  &.gender-alt {
    & .ant-form-item-control-input-content {
      & input {
        width: 33%;
      }

      & span {
        font-size: 0.875rem;
        color: #061c2e;
        margin-left: 0.5rem;
      }
    }
  }

  &.mw-90 {
    max-width: 90%;
  }
  &.m-4 {
    margin-top: 2.5rem;
  }
  &.mb-1 {
    margin-bottom: 1rem;
  }
  &.mb-5 {
    margin-bottom: 2.5rem;
  }
  &.hidden-input {
    height: 0;
    margin: 0;
    & .ant-form-item-control-input {
      height: 0;
      min-height: initial;
      max-height: 0;
      & input {
        height: 0;
        padding: 0;
        border: none;
      }
    }
  }
  &.text-grey {
    & .ant-form-item-label {
      & label {
        color: #627d95;
      }
    }
  }
  &.hide-error {
    &.ant-form-item-has-error .ant-form-item-explain-error {
      display: none;
    }
    &.ant-form-item.ant-form-item-has-error {
      & .ant-input {
        border: 1px solid #aebeca;
      }
    }
  }
`;
const FormItem = ({ children, ...rest }: FormItemProps) => {
  return (
    <StyledFormItem validateTrigger={'onSubmit'} {...rest}>
      {children}
    </StyledFormItem>
  );
};

export default FormItem;
