import { Col, Row, Slider } from 'antd';
import FormItem from '../../components/form/FormItem';
import { InputNumberInput } from '../buttons/NumberInput';
import styled from 'styled-components';

type SliderNumberFormInputProps = {
  name: string;
  scaleFactor?: number;
  min?: number;
  max?: number;
  sliderStep?: number;
  inputStep?: number;
  formatter?: (value: number) => string;
  onChange?: (value: string | number) => void;
};

const percentageFormatter = (value: number) => `${value} %`;

const StyledInputNumberInput = styled(InputNumberInput)`
  &.ant-input-number {
    max-width: 8rem;
  }
  .ant-input-number-input {
    text-align: right;
  }
`;

const StyledSlider = styled(Slider)`
  margin: 16px 32px 0 0;
  .ant-slider-track {
    background-color: ${({ theme }) => theme.primaryColor};
  }
  &:hover .ant-slider-track {
    background-color: ${({ theme }) => theme.hoverColor};
  }
  .ant-slider-handle {
    border: solid 2px ${({ theme }) => theme.primaryColor};
    &:focus {
      box-shadow: 0 0 0 5px ${({ theme }) => theme.ghostHoverColor};
    }
  }
  &:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${({ theme }) => theme.hoverColor};
  }
`;

const SliderNumberFormInput = ({
  name,
  scaleFactor = 1,
  min = 0,
  max = 100,
  sliderStep = 1,
  inputStep = 1,
  formatter = percentageFormatter,
  onChange,
}: SliderNumberFormInputProps) => {
  return (
    <Row>
      <Col xs={{ span: 16 }}>
        <FormItem noStyle name={name}>
          <StyledSlider
            range={false}
            min={min}
            max={max}
            step={sliderStep}
            tipFormatter={(value) => formatter((value || 0) / scaleFactor)}
            onChange={(value: unknown) => { if (onChange) onChange(value as number) }}
          />
        </FormItem>
      </Col>
      <Col xs={{ span: 8 }}>
        <FormItem noStyle name={name}>
          <StyledInputNumberInput
            maxLength={4}
            size="large"
            min={min}
            max={max}
            step={inputStep}
            formatter={(value) => formatter((Number.parseFloat(String(value || 0)) || 0) / scaleFactor)}
            parser={(displayValue) => Math.round(Number.parseFloat(displayValue || '') * scaleFactor)}
            onChange={(value) => { if (onChange) onChange(value) }}
          />
        </FormItem>
      </Col>
    </Row>
  )
};

export default SliderNumberFormInput;
