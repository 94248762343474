import React, { useEffect, useState } from 'react';
import { Form, Select, Col, Row, message } from 'antd';
import FormTitle from '../../components/auth/FormTitle';
import Button from '../../UI/buttons/Button';
import styled from 'styled-components';
import FormItem from './FormItem';
import { CaretDownOutlined } from '@ant-design/icons';
import SelectInput from '../../UI/inputs/Select';
import Input from '../../UI/inputs/Input';
import { FEE_TYPES } from '../recruiter/AddCompanyRecruiterForm';
import { updateJobFlexibleFee } from '../../api/jobs';
import { ClientFeeTemplate, Job } from '../../types';
import { JOBS } from '../../constants/routes';
import {
  HIREPORT_DEFAULT_COMPENSATION_TYPE,
  HIREPORT_DEFAULT_GUARANTEE_PERIOD,
} from '../../constants/recruiters';
import { useTranslation } from 'react-i18next';
import { getCompanyFeeTemplates } from '../../api/company';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow-down.svg';
import {
  ActionLink,
  ActionWrapper,
  DeleteFileIcon,
  FileName,
  FileUploader,
  StyledUploader,
  UploadAction,
} from '../recruitment_management/RecruiterEditAgreement';
import { ReactComponent as DownloadIcon } from '../../icons/download.svg';
import { RcFile } from 'antd/lib/upload';

import { useAppSelector } from '../../store/hooks';
import { useHistoryWithUrlBase } from '../../hooks/useHirePortContext';
import { useGetEmploymentTypeNameById } from '../../hooks/useEmploymentTypes';
import Textarea from '../../UI/inputs/Textarea';
import { PAYMENT_TERM_START_TYPES, PAYMENT_TERM_TYPES } from '../client/MasterContractForm';
import useCurrency from '../../hooks/useCurrency';
import CurrencySelect from './shared/currencies/CurrencySelect';
import TransactionFeeFormItem from './shared/TransactionFeeFormItem';

const FormActions = styled.div`
  padding: 1.5rem 2rem;
  border-radius: ${({ theme }) => theme.radius};
  background-color: ${({ theme }) => theme.lozengeColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormSubtitle = styled.h2`
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #061c2e;
  margin: 0.5rem 0;
`;

type JobPSLFormProps = {
  job: Job;
  onFinish: (id: string) => void;
};

export default function JobFeeSettings({
  job,
  onFinish,
}: JobPSLFormProps) {
  const [t] = useTranslation();
  const defaultCurrency = useCurrency(job.compensation_currency);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [form] = Form.useForm();
  const history = useHistoryWithUrlBase();
  const { user } = useAppSelector((state) => state.user);
  const marketplaceAgreementOverridesEnabled =
    user?.company?.extended_recruiter_agreement_enabled;
  const masterContractsEnabled =
    (user?.role === 'client' && user?.company?.company_wide_fee_settings_enabled) || false;
  const transactionFeeEnabled =
    user?.company?.transaction_fee_enabled;

  let [loading, setLoading] = useState(false);
  const [companyFeeTemplates, setCompanyFeeTemplates] = useState<
    ClientFeeTemplate[]
  >([]);
  const [showExtendedFeeSettings, setShowExtendedFeeSettings] = useState(false);
  const [file, setFile] = useState(job?.contract ?? '');
  const [newFileUploading, setNewFileUploading] = useState(false);
  const [selectedMasterTemplateId, setSelectedMasterTemplateId] = useState('');
  const getEmploymentTypeNameById = useGetEmploymentTypeNameById();

  const initialFormValues = {
    recruiter_compensation_type:
      job.recruiter_compensation_type ?? HIREPORT_DEFAULT_COMPENSATION_TYPE,
    recruiter_compensation_type_tooltip: job.recruiter_compensation_type_tooltip
      ? Number(job.recruiter_compensation_type_tooltip)
      : null,
    fee_currency: job.fee_currency,
    payment_terms:
      PAYMENT_TERM_TYPES.find((x) => x.value === job.payment_terms)?.value ??
      PAYMENT_TERM_TYPES[0].value,
    restitution: job.restitution,
    restitution_payment_terms:
      PAYMENT_TERM_TYPES.find((x) => x.value === job.restitution_payment_terms)
        ?.value ?? PAYMENT_TERM_TYPES[0].value,
    payment_terms_start: job.payment_terms_start,
    custom_restitution_agreement: job.custom_restitution_agreement,
    other_information: job.other_information,
    replacement_time: job.replacement_time,
    guarantee_period: job.guarantee_period ?? HIREPORT_DEFAULT_GUARANTEE_PERIOD,
    exclusivity_for_replacement:
      job.exclusivity_for_replacement === true
        ? 'Yes'
        : job.exclusivity_for_replacement === false
          ? 'No'
          : null,
    et_details_freelance: job.et_details_freelance,
    et_details_ctp: job.et_details_ctp,
    et_gda_ctp: job.et_gda_ctp,
    et_cao_ctp: job.et_cao_ctp,
    transaction_fee_to_company_percent: job.transaction_fee_to_company_percent,
  };

  const employmentType = job.employment_type?.type.id || "";

  const handleClick = (nextPage: boolean) => {
    try {
      setLoading(true);
      const draft = form.getFieldsValue();
      const data = new FormData();

      data.append(
        'recruiter_compensation_type',
        draft.recruiter_compensation_type ?? '',
      );
      data.append(
        'recruiter_compensation_type_tooltip',
        draft.recruiter_compensation_type_tooltip ?? '',
      );
      data.append('fee_currency', draft.fee_currency ?? '');
      data.append('payment_terms', draft.payment_terms ?? '');
      data.append('restitution', draft.restitution ?? '');
      data.append(
        'restitution_payment_terms',
        draft.restitution_payment_terms ?? '',
      );
      data.append('replacement_time', draft.replacement_time ?? '');
      data.append('guarantee_period', draft.guarantee_period ?? '');
      data.append('other_information', draft.other_information ?? '');
      data.append('payment_terms_start', draft.payment_terms_start ?? '');
      data.append(
        'custom_restitution_agreement',
        draft.custom_restitution_agreement ?? '',
      );
      data.append(
        'exclusivity_for_replacement',
        draft.exclusivity_for_replacement === 'Yes'
          ? 'true'
          : draft.exclusivity_for_replacement === 'No'
            ? 'false'
            : '',
      );
      data.append('et_details_freelance', draft.et_details_freelance ?? '');
      data.append('et_details_ctp', draft.et_details_ctp ?? '');
      data.append('et_gda_ctp', draft.et_gda_ctp ?? '');
      data.append('et_cao_ctp', draft.et_cao_ctp ?? '');
      data.append('transaction_fee_to_company_percent', draft.transaction_fee_to_company_percent ?? '0');

      if (draft.contract?.file?.originFileObj) {
        data.append('contract', draft.contract.file.originFileObj);
      } else if (file) {
        data.append('contract_url', file as string);
      } else if (!file) {
        data.append('contract', '');
      }

      updateJobFlexibleFee({
        jobId: job.id,
        payload: data,
      })
        .then(() => {
          setLoading(false);
          message.success(t('JOB_UPDATED'));
          if (!nextPage) history.push(`${JOBS}/${job?.id}`);
        })
        .catch(() => {
          setLoading(false);
          message.error(t('UPDATE_ERROR'));
        });

      if (nextPage) {
        onFinish(job.id);
      }
    } catch (error) {
      message.error(t('UPDATE_ERROR'));
    }
  };

  const handleUploadFile = (file: RcFile): boolean => {
    setFile(file.name);
    setNewFileUploading(true);
    return true;
  };

  const handleFileDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile('');
    form.setFieldsValue({ contract: null });
  };

  const onReset = () => {
    form.setFieldsValue(initialFormValues);
    setSelectedMasterTemplateId("");
  }

  const onFeeTemplateSelect = (id: string) => {
    setSelectedMasterTemplateId(id);
  }

  const onFeeTemplateLoad = () => {
    if (!selectedMasterTemplateId)
      return;

    const feeTemplate = companyFeeTemplates.find(
      (template) => template.id === selectedMasterTemplateId,
    );

    if (feeTemplate?.id) {
      form.setFieldsValue({
        ...feeTemplate,
        name: form.getFieldValue('name'),
        exclusivity_for_replacement:
          feeTemplate.exclusivity_for_replacement === true
            ? 'Yes'
            : feeTemplate.exclusivity_for_replacement === false
              ? 'No'
              : '',
        recruiter_compensation_type: feeTemplate.compensation_type,
        recruiter_compensation_type_tooltip: feeTemplate.compensation_type_tooltip,
        fee_currency: feeTemplate.fee_currency,
      });
      setFile(feeTemplate.contract ?? '');
    }
  };

  useEffect(() => {
    if (masterContractsEnabled) {
      getCompanyFeeTemplates().then((res: any) => {
        setCompanyFeeTemplates(res.data ?? []);
      });
    }
  }, [masterContractsEnabled, setCompanyFeeTemplates]);

  return (
    <Form
      scrollToFirstError={true}
      layout="vertical"
      name="job-form"
      form={form}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      initialValues={initialFormValues}
    >
      <div>
        <FormTitle text={t('JOB_FEE_SETTINGS')} />
        <p>{t('PSL_FORM_FEE_DESCRIPTION')}</p>
        {masterContractsEnabled && companyFeeTemplates.length > 0 && (
          <Row
            gutter={16}
            style={{
              marginTop: '1.5rem',
            }}
          >
            <Col span={16}>
              <FormItem label="LOAD SETTINGS FROM MASTER CONTRACT">
                <Row gutter={16}>
                  <Col span={12}>
                    <SelectInput
                      size="large"
                      suffixIcon={
                        <CaretDownOutlined style={{ color: '#000' }} />
                      }
                      value={selectedMasterTemplateId}
                      onChange={onFeeTemplateSelect}
                    >
                      {companyFeeTemplates.map((feeTemplate) => (
                        <Select.Option
                          value={feeTemplate.id}
                          key={feeTemplate.id}
                        >
                          {feeTemplate.name}
                        </Select.Option>
                      ))}
                    </SelectInput>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => onFeeTemplateLoad()}
                    >
                      {t("Load")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="ghost"
                      size="large"
                      onClick={() => onReset()}
                    >
                      {t("Reset")}
                    </Button>
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>
        )}
        <div
          style={{
            width: '100%',
            marginTop: '1rem',
            borderBottom: '1px solid #DAE5EC',
          }}
        >
          <FormSubtitle
            style={{ fontSize: '16px', paddingBottom: '1rem' }}
          >
            {t('FEE_SETUP_FEE_INFORMATION')}
          </FormSubtitle>
        </div>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <Col span={8}>
            <FormItem
              label={t('FEE_CURRENCY_FIELD_LABEL')}
              name="fee_currency"
            >
              <CurrencySelect
                size="large"
                onChange={(value) => setCurrency(currency.changeCurrency(value))}
              />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          hidden={['freelance', 'ctp'].includes(employmentType)}
        >
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            <Col span={8}>
              <FormItem
                label="FEE STRUCTURE"
                name="recruiter_compensation_type"
                className="no-margin"
              >
                <SelectInput
                  size="large"
                  suffixIcon={
                    <CaretDownOutlined style={{ color: '#000' }} />
                  }
                >
                  {FEE_TYPES.map((fee, index) => (
                    <Select.Option value={fee.fee_value} key={index}>
                      {fee.fee_name}
                    </Select.Option>
                  ))}
                </SelectInput>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label={t("FEE_DETAILS_FORM_LABEL")}
                name="recruiter_compensation_type_tooltip"
                className="no-margin"
              >
                <Input
                  type="number"
                  placeholder={t('JOB_FEE_DETAILS_PLACEHOLDER')}
                  maxLength={70}
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem
          hidden={employmentType !== 'freelance'}
        >
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            <Col span={8}>
              <FormItem
                label="FREELANCE FEE DETAILS"
                name="et_details_freelance"
                className="no-margin"
              >
                <Input
                  type="text"
                  maxLength={70}
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem
          hidden={employmentType !== 'ctp'}
        >
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            <Col span={8}>
              <FormItem
                label={t('JOB_ET_DETAILS', { employmentType: getEmploymentTypeNameById("ctp") })}
                name="et_details_ctp"
                className="no-margin"
              >
                <Input
                  type="text"
                  maxLength={70}
                  size="large"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label={t('JOB_ET_CAO', { employmentType: getEmploymentTypeNameById("ctp") })}
                name="et_cao_ctp"
                className="no-margin"
              >
                <Input
                  type="text"
                  maxLength={70}
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            <Col span={16}>
              <FormItem
                label={t('JOB_ET_GDA', { employmentType: getEmploymentTypeNameById("ctp") })}
                name="et_gda_ctp"
                className="no-margin"
              >
                <Textarea
                  autoSize={{ minRows: 3 }}
                />
              </FormItem>
            </Col>
          </Row>
        </FormItem>
        <FormItem
          hidden={!marketplaceAgreementOverridesEnabled}
        >
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            <Col
              span={8}
              style={{ alignSelf: 'end', marginBottom: '1.5rem' }}
            >
              <Button
                type="ghost"
                size="middle"
                onClick={() =>
                  setShowExtendedFeeSettings(!showExtendedFeeSettings)
                }
              >
                {t(showExtendedFeeSettings ? 'RECRUITER_MANAGEMENT_HIDE_EXTENDED_FIELDS' : 'RECRUITER_MANAGEMENT_SHOW_EXTENDED_FIELDS')}
                {showExtendedFeeSettings ? (
                  <ArrowDownIcon
                    style={{ marginRight: '-1rem', rotate: '180deg' }}
                  />
                ) : (
                  <ArrowDownIcon style={{ marginRight: '-1rem' }} />
                )}
              </Button>
            </Col>
          </Row>
        </FormItem>
        <FormItem
          hidden={!showExtendedFeeSettings}
        >
          <FormItem
            hidden={['freelance', 'ctp'].includes(employmentType)}
          >
            <Row gutter={16}>
              <Col span={8}>
                <FormItem
                  label={t('GUARANTEE_PERIOD')}
                  name="guarantee_period"
                  className="no-margin"
                >
                  <Input
                    placeholder={t('GUARANTEE_PERIOD_PLACEHOLDER')}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle
                style={{ fontSize: '16px', paddingBottom: '1rem' }}
              >
                {t('FEE_SETUP_RESTITUTION')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_RESTITUTION')}
                  name="restitution"
                  className="no-margin"
                >
                  <Input
                    placeholder={t(
                      'COMPANY_AGREEMENT_RESTITUTION_PLACEHOLDER',
                    )}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_REPLACEMENT_TIME')}
                  name="replacement_time"
                  className="no-margin"
                >
                  <Input
                    placeholder={t(
                      'COMPANY_AGREEMENT_REPLACEMENT_TIME_PLACEHOLDER',
                    )}
                    maxLength={70}
                    size="large"
                    type="number"
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_EXCLUSIVITY_FOR_REPLACEMENT')}
                  name="exclusivity_for_replacement"
                  className="no-margin"
                >
                  <SelectInput
                    placeholder={t(
                      'COMPANY_AGREEMENT_EXCLUSIVITY_FOR_REPLACEMENT_PLACEHOLDER',
                    )}
                    size="large"
                    allowClear
                    suffixIcon={
                      <CaretDownOutlined style={{ color: '#000' }} />
                    }
                  >
                    {['Yes', 'No'].map((val, index) => (
                      <Select.Option value={val} key={index}>
                        {val}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={24}>
                <FormItem
                  label={t('JOB_CUSTOM_RESTITUTION_AGREEMENT')}
                  name="custom_restitution_agreement"
                  className="no-margin"
                >
                  <Input maxLength={70} size="large" type="text" />
                </FormItem>
              </Col>
            </Row>
            <div
              style={{
                width: '100%',
                marginTop: '2.5rem',
                borderBottom: '1px solid #DAE5EC',
              }}
            >
              <FormSubtitle
                style={{ fontSize: '16px', paddingBottom: '1rem' }}
              >
                {t('FEE_SETUP_PAYMENT_TERMS')}
              </FormSubtitle>
            </div>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_PAYMENT_TERMS')}
                  name="payment_terms"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={
                      <CaretDownOutlined style={{ color: '#000' }} />
                    }
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option
                        value={payment_term.value}
                        key={index}
                      >
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={t('JOB_PAYMENT_TERMS_START')}
                  name="payment_terms_start"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={
                      <CaretDownOutlined style={{ color: '#000' }} />
                    }
                  >
                    {PAYMENT_TERM_START_TYPES.map(
                      (payment_term, index) => (
                        <Select.Option
                          value={payment_term.value}
                          key={index}
                        >
                          {payment_term.name}
                        </Select.Option>
                      ),
                    )}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '1rem' }}>
              <Col span={8}>
                <FormItem
                  label={t('JOB_RESTITUTION_PAYMENT_TERMS')}
                  name="restitution_payment_terms"
                  className="no-margin"
                >
                  <SelectInput
                    size="large"
                    suffixIcon={
                      <CaretDownOutlined style={{ color: '#000' }} />
                    }
                  >
                    {PAYMENT_TERM_TYPES.map((payment_term, index) => (
                      <Select.Option
                        value={payment_term.value}
                        key={index}
                      >
                        {payment_term.name}
                      </Select.Option>
                    ))}
                  </SelectInput>
                </FormItem>
              </Col>
            </Row>
          </FormItem>
          <div
            style={{
              width: '100%',
              marginTop: '2.5rem',
              borderBottom: '1px solid #DAE5EC',
            }}
          >
            <FormSubtitle
              style={{ fontSize: '16px', paddingBottom: '1rem' }}
            >
              {t('JOB_OTHER_INFORMATION')}
            </FormSubtitle>
          </div>
          <Row gutter={16} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Col span={24}>
              <FormItem name="other_information" className="no-margin" label={t('JOB_OTHER_INFORMATION')}>
                <Input maxLength={70} size="large" type="text" />
              </FormItem>
            </Col>
          </Row>
          {transactionFeeEnabled && (
            <TransactionFeeFormItem form={form} name="transaction_fee_to_company_percent" />
          )}
          <div
            style={{
              width: '100%',
              marginTop: '2.5rem',
              borderBottom: '1px solid #DAE5EC',
            }}
          >
            <FormSubtitle
              style={{ fontSize: '16px', paddingBottom: '1rem' }}
            >
              {t('RECRUITER_CONTRACT')}
            </FormSubtitle>
          </div>
          <Row gutter={16} style={{ marginTop: '1rem' }}>
            <>
              {file && !newFileUploading ? (
                <div style={{ display: 'flex' }}>
                  <ActionWrapper>
                    <ActionLink
                      href={file as string}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DownloadIcon />
                      {t('COMPANY_AGREEMENT_DOWNLOAD_CONTRACT')}
                    </ActionLink>
                  </ActionWrapper>
                  <UploadAction>
                    <button
                      style={{
                        border: 'none',
                        display: 'flex',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <DeleteFileIcon onClick={handleFileDelete} />
                    </button>
                  </UploadAction>
                </div>
              ) : (
                <Col span={8}>
                  <FormItem
                    label={t('COMPANY_AGREEMENT_CONTRACT')}
                    name="contract"
                  >
                    <StyledUploader
                      name="file"
                      showUploadList={false}
                      beforeUpload={handleUploadFile}
                      customRequest={() => void 0}
                    >
                      <FileUploader>
                        <FileName>{file}</FileName>
                        <UploadAction>
                          <Button type={'default'} size="small">
                            {t('CHOOSE_FILE')}
                          </Button>

                          {file && (
                            <DeleteFileIcon
                              onClick={handleFileDelete}
                            />
                          )}
                        </UploadAction>
                      </FileUploader>
                    </StyledUploader>
                  </FormItem>
                </Col>
              )}
            </>
          </Row>
        </FormItem>
      </div>
      <FormActions>
        <Button
          type="ghost"
          loading={loading}
          onClick={() => handleClick(false)}
        >
          {t('SAVE_AND_PREVIEW_DRAFT_TITLE')}
        </Button>
        <Button type="primary" onClick={(e) => handleClick(true)}>
          {t('NEXT_TITLE')}
        </Button>
      </FormActions>
    </Form>
  );
}
