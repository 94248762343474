import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Button from '../../UI/buttons/Button';
import Textarea from '../../UI/inputs/Textarea';
import NameAvatar from '../../UI/NameAvatar';
import { Contact } from '../../types';

export const RoomContainer = styled.section`
  display: flex;
  background: #fff;
  border-radius: 8px 8px 0 0;
  flex: 1;
  margin-left: 1rem;
  height: ${({ theme }) => theme.renderPageHeader ? 'calc(100vh - 80px)' : '100vh'};
  @media (max-width: 768px) {
    margin: 0;
    height: 100vh;
    &.hide-mobile {
      display: none;
    }
  }
`;

export const MessagesContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  flex: 1;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 0;
    &.hide-mobile {
      display: none;
    }
  }
`;

export const CompanyMessagesContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 15%);
  padding: 0;
  flex: 1;
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 0;
    &.hide-mobile {
      display: none;
    }
  }
`;

export const InterviewContainer = styled.section`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dae5ec;
  padding: 1rem 2rem;
  @media (max-width: 768px) {
    width: 100%;
    border: none;
    padding: 0;
    &.hide-mobile {
      display: none;
    }
  }
`;

export const InterviewListHeader = styled.div`
  padding: 1rem 0;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  border-bottom: 1px solid #dae5ec;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MessagesListHeaderWrapper = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid #dae5ec;
  & .back {
    display: none;
  }
  @media (max-width: 768px) {
    background: #ffffff;
    width: 100%;
    padding: 1.25rem;
    box-shadow: 0 10px 10px -5px #00000013;
    z-index: 999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & .back {
      min-width: 1.25rem;
      display: block;
      margin-right: 1rem;
    }
  }
`;

export const MessagesListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const MessageHeaderItem = styled.p`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 0;
  a {
    color: #061c2e;
  }
  @media (max-width: 768px) {
    &:first-of-type {
      width: 100%;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.71;
      color: #061c2e;
    }
  }
`;

export const InterviewsList = styled.ul`
  list-style: none;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 272px;
  margin: 1rem 0 0;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    max-height: 100%;
    padding: 1rem;
  }
`;

interface MessagesListProps extends React.HTMLAttributes<HTMLDivElement> {
  empty: boolean;
  withHeader: boolean;
  expanded: boolean;
}

export const MessagesList = styled.ul<MessagesListProps>`
  list-style: none;
  transition: all ease 0.3s;
  padding-bottom: ${(props) => (props.expanded ? '415px' : '5rem')};
  padding-right: 5px;
  height: ${(props) =>
    props.empty
      ? props.withHeader
        ? 'calc(100vh - 160px);'
        : 'calc(100vh - 100px);'
      : 'calc(100vh - 160px);'};
  max-height: ${(props) =>
    props.empty
      ? props.withHeader
        ? 'calc(100vh - 135px);'
        : 'calc(100vh - 85px);'
      : 'calc(100vh - 135px);'};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin: 0.5rem 0 0;
  position: relative;
  display: ${(props) => (props.empty ? 'inherit' : 'flex')};
  flex-direction: ${(props) => (props.empty ? 'inherit' : 'column-reverse')};
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const CompanyChatMessagesList = styled.ul<MessagesListProps>`
  list-style: none;
  transition: all ease 0.3s;
  padding-bottom: ${(props) => (props.expanded ? '415px' : '5rem')};
  padding-right: 5px;
  height: ${(props) =>
    props.empty
      ? props.withHeader
        ? 'calc(100vh - 330px);'
        : 'calc(100vh - 100px);'
      : 'calc(100vh - 330px);'};
  max-height: ${(props) =>
    props.empty
      ? props.withHeader
        ? 'calc(100vh - 330px);'
        : 'calc(100vh - 85px);'
      : 'calc(100vh - 330px);'};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin: 0.5rem 0 0;
  position: relative;
  display: ${(props) => (props.empty ? 'inherit' : 'flex')};
  flex-direction: ${(props) => (props.empty ? 'inherit' : 'column-reverse')};
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const MessageItem = styled.li`
  position: relative;
  padding: 0.75rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dae5ec;

  &:last-child {
    border: none;
  }

  &:hover {
    background: #fff;
  }
`;

interface MessageTextProps {
  system: boolean;
  disabled: boolean;
}

export const MessageText = styled.p<MessageTextProps>`
  padding: ${(props) => (props.system ? '2rem' : '0')};
  background: ${({ theme, system }) => (system ? theme.lozengeColor : 'inherit')};
  border-radius: ${({ theme }) => theme.radius};

  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};

  font-weight: ${(props) => (props.system ? 'bold' : 'inherit')};
  white-space: pre-wrap;
`;

export const MessageButton = styled(Button)`
  margin-top: 1rem;

  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;

  &.attached-file-button {
     padding: 0.813rem 2rem !important;
     display: table;
  }
`;

export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.875rem;
`;

export const MessageSenderInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const MessageSenderAvatar = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-position: center;
  border: solid 1px #dae5ec;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const MessageSenderName = styled.h5`
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin: 0 0 0 0.5rem;
`;

export const StyledAvatar = styled(NameAvatar)`
  min-width: 1.5rem !important;
  min-height: 1.5rem !important;
  width: 1.5rem;
  height: 1.5rem;
  & .ant-avatar-string {
    font-size: 11px;
  }
`;

export const MessageDatetime = styled.p`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #627d95;
  margin: 0;
  position: relative;
`;

interface NewMessageProps {
  expanded: boolean;
}

export const NewMessage = styled.div<NewMessageProps>`
  position: absolute;
  bottom: 0;
  left: 2rem;
  right: 2rem;
  height: ${(props) => (props.expanded ? '400px' : '65px')};
  padding: 1rem 2rem 0;
  box-shadow: 0 2px 12px 0 rgba(6, 28, 46, 0.12),
    0 2px 4px 0 rgba(6, 28, 46, 0.1);
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  transition: all ease 0.3s;
  @media (max-width: 768px) {
    right: 0;
    left: 0;
    height: ${(props) => (props.expanded ? '300px' : '65px')};
  }
`;

export const NewCompanyChatMessage = styled.div<NewMessageProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${(props) => (props.expanded ? '400px' : '65px')};
  padding: 1rem 2rem 0;
  box-shadow: 0 2px 12px 0 rgba(6, 28, 46, 0.12),
    0 2px 4px 0 rgba(6, 28, 46, 0.1);
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  transition: all ease 0.3s;
  @media (max-width: 768px) {
    right: 0;
    left: 0;
    height: ${(props) => (props.expanded ? '300px' : '65px')};
  }
`;

export const NewMessageHeader = styled.div<NewMessageProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #061c2e;
  text-transform: uppercase;
  padding: 1rem 0;
  cursor: pointer;
  border-bottom: ${(props) => (props.expanded ? '1px solid #dae5ec' : 'none')};
  margin-bottom: ${(props) => (props.expanded ? '1.5rem' : '0')};
`;

export const RoundButton = styled.button`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background: #34ce72;
  color: #ffffff;
  border: none;
  outline: none;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
  z-index: 999;

  &[disabled] {
    background: grey;
    cursor: not-allowed;
  }

  &.top-button {
    right: 5rem;
  }

  #file-input {
    display: none;
  }
`;

interface InterviewItemProps {
  active: boolean;
}

export const InterviewItem = styled.li<InterviewItemProps>`
  position: relative;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => (props.active === true ? '8px' : '0')};
  cursor: pointer;
  background: ${(props) => (props.active === true ? '#f2f6fa' : 'transparent')};
  margin-bottom: 5px;
  border-bottom: 1px solid #dae5ec;

  &:hover {
    background: #f2f6fa;
    border-radius: 8px;
    border-bottom: 1px solid #fff;
  }
`;

export const InterviewItemTitle = styled.h6`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #061c2e;
  margin-bottom: 0.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80%;
`;

export const InterviewItemDescription = styled.h6`
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  margin-bottom: 0.25rem;
  max-width: 80%;
`;

export const UnreadCounter = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  bottom: 1.6rem;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.96px;
  text-align: left;
  color: #ffffff;
  background: #34ce72;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const EmptyText = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #aebeca;
  padding: 5rem 0;
`;

export const MessagesListHeaderSmall = styled.span`
  margin-left: 16px;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #627d95;
  a {
    color: #627d95;
  }
  text-transform: none !important;
  @media (max-width: 768px) {
    font-size: 0.625rem;
    line-height: 1.6;
    color: #627d95;
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
`;

export const NewMessageField = styled(Textarea)`
  &.ant-input {
    border: none;
    &:hover {
      border: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

export interface RoomProps extends React.HTMLAttributes<HTMLDivElement> {
  searchQuery: string;
  contact?: Contact | null;
  setContact: (contact: Contact | null) => void;
  showContactPanel?: boolean;
  showHeader?: boolean;
  interviewId: string | null;
}

export const Title = styled.p`
  font-size: 0.75rem;
  line-height: 1.33;
  text-align: left;
  color: #627d95;
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
`;

export const DateText = styled.span`
  &.danger {
    color: #eb5757;
  }
`;

export const HeaderMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    background: #ffffff;
    width: 100%;
    padding: 1.25rem;
    box-shadow: 0 10px 10px -5px #00000013;
    z-index: 999;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const HeaderMobileLeft = styled.div`
    background: #ffffff;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
`;

export const CompanyLogo = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: solid 1px #dae5ec;
  margin: 0 0.5rem 0 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffffff;
`;

export const CompanyName = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  text-align: left;
  color: #061c2e;
  margin: 0;
`;

export const LogoLink = styled(Link)`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
