import { Job } from '../types';

export const formatJobFeeFields = (job: Job, isClient: boolean) => {
  const compensation_type = isClient
    ? job.recruiter_company_agreement?.compensation_type
    : job.company_agreement?.compensation_type ||
    job.recruiter_compensation_type ||
    null;
  const compensation_type_tooltip = isClient
    ? job.recruiter_company_agreement?.compensation_type_tooltip
    : job.company_agreement?.compensation_type_tooltip ||
    job.recruiter_compensation_type_tooltip ||
    null;
  const payment_terms = isClient
    ? job.payment_terms
    : job.recruiter_company_agreement?.payment_terms ||
    job.company_agreement?.payment_terms ||
    job.payment_terms ||
    null;
  const guarantee_period = isClient
    ? job.guarantee_period
    : job.recruiter_company_agreement?.guarantee_period ||
    job.company_agreement?.guarantee_period ||
    job.guarantee_period ||
    null;
  const restitution = isClient
    ? job.restitution
    : job.recruiter_company_agreement?.restitution ||
    job.company_agreement?.restitution ||
    job.restitution ||
    null;
  const restitution_payment_terms = isClient
    ? job.restitution_payment_terms
    : job.recruiter_company_agreement?.restitution_payment_terms ||
    job.company_agreement?.restitution_payment_terms ||
    job.restitution_payment_terms ||
    null;
  const replacement_time = isClient
    ? job.replacement_time
    : job.recruiter_company_agreement?.replacement_time ||
    job.company_agreement?.replacement_time ||
    job.replacement_time ||
    null;
  const exclusivity_for_replacement = isClient
    ? job.exclusivity_for_replacement
    : job.recruiter_company_agreement?.exclusivity_for_replacement ||
    job.company_agreement?.exclusivity_for_replacement ||
    job.exclusivity_for_replacement ||
    null;
  const et_details_freelance = isClient
    ? job.et_details_freelance
    : job.recruiter_company_agreement?.et_details_freelance ||
    job.company_agreement?.et_details_freelance ||
    job.et_details_freelance ||
    null;
  const et_details_ctp = isClient
    ? job.et_details_ctp
    : job.recruiter_company_agreement?.et_details_ctp ||
    job.company_agreement?.et_details_ctp ||
    job.et_details_ctp ||
    null;
  const et_gda_ctp = isClient
    ? job.et_gda_ctp
    : job.recruiter_company_agreement?.et_gda_ctp ||
    job.company_agreement?.et_gda_ctp ||
    job.et_gda_ctp ||
    null;
  const et_cao_ctp = isClient
    ? job.et_cao_ctp
    : job.recruiter_company_agreement?.et_cao_ctp ||
    job.company_agreement?.et_cao_ctp ||
    job.et_cao_ctp ||
    null;
  const transaction_fee_to_company_percent = isClient
    ? job.transaction_fee_to_company_percent
    : job.recruiter_company_agreement?.transaction_fee_to_company_percent ||
    job.company_agreement?.transaction_fee_to_company_percent ||
    job.transaction_fee_to_company_percent ||
    null;
  const contract = isClient
    ? job.contract
    : job.recruiter_company_agreement?.contract || null;
  const payment_terms_start = isClient
    ? job.payment_terms_start
    : job.recruiter_company_agreement?.payment_terms_start ||
    job.company_agreement?.payment_terms_start ||
    job.payment_terms_start ||
    null;
  const custom_restitution_agreement = isClient
    ? job.custom_restitution_agreement
    : job.recruiter_company_agreement?.custom_restitution_agreement ||
    job.company_agreement?.custom_restitution_agreement ||
    job.custom_restitution_agreement ||
    null;
  const other_information = isClient
    ? job.other_information
    : job.recruiter_company_agreement?.other_information ||
    job.company_agreement?.other_information ||
    job.other_information ||
    null;

  return {
    compensation_type,
    compensation_type_tooltip,
    payment_terms,
    guarantee_period,
    restitution,
    restitution_payment_terms,
    replacement_time,
    exclusivity_for_replacement,
    et_details_freelance,
    et_details_ctp,
    et_gda_ctp,
    et_cao_ctp,
    transaction_fee_to_company_percent,
    payment_terms_start,
    custom_restitution_agreement,
    other_information,
    contract,
  };
};
