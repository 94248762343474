import { useTranslation } from 'react-i18next';
import FormItem from '../FormItem';
import Switch from '../../../UI/Switch';
import { useState } from 'react';
import SliderNumberFormInput from '../../../UI/inputs/SliderNumberFormInput';
import { FormInstance } from 'antd/es/form/Form';

type TransactionFeeFormItemProps = {
  form: FormInstance;
  name: string;
  onChange?: (value: string | number) => void;
};

const TransactionFeeFormItem = ({ form, name, onChange }: TransactionFeeFormItemProps) => {
  const [t] = useTranslation();
  const [lastFeeValue, setLastFeeValue] = useState<string | number>(0);
  const [controlIsVisible, setControlIsVisible] = useState(!!form.getFieldValue(name));

  return (
    <FormItem
      label={t('TRANSACTION_FEE_TO_COMPANY')}
      tooltip={t('TRANSACTION_FEE_TO_COMPANY_TOOLTIP')}
      className="no-margin"
    >
      <>
        <Switch
          checked={controlIsVisible}
          onChange={(checked) => {
            const newValue = checked ? lastFeeValue : 0;
            form.setFieldsValue({ [name]: newValue });
            setControlIsVisible(checked);
            if (onChange)
              onChange(newValue);
          }} />
        {t('TRANSACTION_FEE_TO_COMPANY_PERCENT')}
        <FormItem hidden={!controlIsVisible}>
          <SliderNumberFormInput
            name={name}
            onChange={(value) => {
              setLastFeeValue(value);
              if (onChange)
                onChange(value);
            }}
          />
        </FormItem>
      </>
    </FormItem>
  )
};

export default TransactionFeeFormItem;
