import { ReactNode, createContext, useContext, useMemo } from 'react';
import { HirePortTheme, defaultHireportTheme } from './useHirePortTheme';
import { ThemeProvider } from 'styled-components';
import { useHistory } from 'react-router-dom';

export type HirePortContextType = {
  theme: HirePortTheme;
  renderPageWrapper: boolean;
  renderPageHeader: boolean;
  urlBase: string;
  renderCustom: {
    recruiterPublicProfile?: {
      hideCandidateList: boolean;
    };
    recruiterSelection?: {
      disableLinkToProfile: boolean;
    };
    jobEdit?: {
      hideProgress: boolean;
    }
  };
};

const defaultHireportContext: HirePortContextType = {
  theme: defaultHireportTheme,
  renderPageWrapper: true,
  renderPageHeader: true,
  urlBase: '',
  renderCustom: {},
};

const HirePortContext = createContext(defaultHireportContext);

const useHirePortContext = () => {
  return useContext(HirePortContext) as HirePortContextType;
}

export const useHistoryWithUrlBase = () => {
  const history = useHistory();
  const { urlBase } = useHirePortContext();
  return useMemo(() => ({
    ...history,
    push: (path: any, ...params: any[]) => {
      if (typeof path === 'string') {
        if (!path.startsWith('http:') && !path.startsWith('https:'))
          history.push(`${urlBase}${path}`, ...params);
        else if (typeof window !== 'undefined')
          window.location.href = path;
      }
      else
        history.push(path, ...params);
    },
    replace: (path: any, ...params: any[]) => {
      if (typeof path === 'string')
        history.replace(`${urlBase}${path}`, ...params);
      else
        history.replace(path, ...params);
    },
  }), [history, urlBase]);
}

export const HirePortContextProvider = ({ value = {}, children }: { value?: Partial<HirePortContextType>, children: ReactNode }) => {
  const previousContext = useHirePortContext();
  const newContext = { ...previousContext, ...value };
  return (
    <HirePortContext.Provider value={newContext}>
      <ThemeProvider theme={{ ...newContext.theme, renderPageHeader: newContext.renderPageHeader }}>
        {children}
      </ThemeProvider>
    </HirePortContext.Provider>
  );
}

export default useHirePortContext;
