import { IS_ADMIN_KEY, ADMIN_TOKEN } from '../constants/roles';

export const AccessTokenTypes = ['Bearer', 'Token'] as const;
export type AccessTokenType = typeof AccessTokenTypes[number];

const ACCESS_TOKEN_KEY = 'access_token';
const ACCESS_TOKEN_TYPE_KEY = 'access_token_type';
const ADMIN_TOKEN_KEY = ADMIN_TOKEN;
export const DEFAULT_ACCESS_TOKEN_TYPE: AccessTokenType = 'Token';

export const storeAccessToken = (token: string, tokenType: AccessTokenType = DEFAULT_ACCESS_TOKEN_TYPE) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
  localStorage.setItem(ACCESS_TOKEN_TYPE_KEY, tokenType);
};

// !WB: huh?
// Kept for consistency
/** @deprecated use storeAccessToken */
export const storeToken = (token: string) => {
  storeAccessToken(token);
};

export const storeAdminToken = (token: string) => {
  localStorage.setItem(ADMIN_TOKEN_KEY, token);
};

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
};

// !WB: huh?
// Kept for consistency :shrug: but doesn't provide any added value
/** @deprecated use removeLocalStorageItem or else directly call the API */
export const removeToken = (key: string) => {
  removeLocalStorageItem(key);
};

// !WB: huh?
// Kept for consistency :shrug: but doesn't provide any added value
export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(key);
};

// !WB: huh?
/** @deprecated use getLocalStorageItem or else directly call the API */
export const getToken = (key: string) => getLocalStorageItem(key);

export const getAccessToken = () => getLocalStorageItem(ACCESS_TOKEN_KEY);

export const getAccessTokenType = () => getLocalStorageItem(ACCESS_TOKEN_TYPE_KEY) || DEFAULT_ACCESS_TOKEN_TYPE;

export const storeAdminMarker = () => {
  localStorage.setItem(IS_ADMIN_KEY, 'true');
};

export const storePathname = (pathname: string) => {
  localStorage.setItem('pathname', pathname);
};

export const storeJobId = (jobId: string) => {
  localStorage.setItem('job', jobId);
};

export const storeSearch = (search: string) => {
  localStorage.setItem('search', search);
};
